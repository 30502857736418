import ApplicationController from './application_controller'

/* This is the custom StimulusReflex controller for the Example Reflex.
 * Learn more at: https://docs.stimulusreflex.com
 */
export default class extends ApplicationController {
  static targets = ['specsWrapper']
  connect () {
    super.connect()
    // add your code here, if applicable
  }

  toggleSpecs (event) {
    event.preventDefault()
    event.target.classList.add('d-none')
    this.specsWrapperTarget.classList.remove('d-none');
  }
}
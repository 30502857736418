import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["submit"]
  connect() {
    $('#deleteCollapse').on('shown.bs.collapse', function () {
      $([document.documentElement, document.body]).animate({
        scrollTop: $('#deleteCollapse').offset().top
      }, 200);
    })
  }

  confirm() {
    if (event.target.value === event.target.dataset.match) {
      this.submitTarget.disabled = false;
    } else {
      this.submitTarget.disabled = true;
    }
  }
}
import ApplicationController from './application_controller'
import CableReady from 'cable_ready'

/* This is the custom StimulusReflex controller for the Example Reflex.
 * Learn more at: https://docs.stimulusreflex.com
 */
export default class extends ApplicationController {
  static targets = ['newMessageForm', 'messageContainer', 'newMessageIndicator', 'message', 'endReached']
  
  static values = {
    groupId: Number
  }

  connect () {
    super.connect()

    this.subscription = this.application.consumer.subscriptions.create({ channel: "GroupsChannel", id: this.data.get("id") }, {
      connected: this._connected.bind(this),
      disconnected: this._disconnected.bind(this),
      received: this._received.bind(this)
    })
    // Scroll new messages into view, otherwise scroll to bottom.
    if (this.hasNewMessageIndicatorTarget) {
      this.messageContainerTarget.scrollTop = this.newMessageIndicatorTarget.offsetTop
    } else {
      this.messageContainerTarget.scrollTop = this.messageContainerTarget.scrollHeight;
    }
    
    let options = {
      rootMargin: '0px',
      root: this.messageContainerTarget,
      threshold: 0.8
    }

    // Initialize intersectionObserver for infinite scrolling
    this.intersectionObserver = new IntersectionObserver(messageContainer => this.processIntersectionEntries(messageContainer), options)
    if (this.hasMessageTarget && !this.hasEndReachedTarget) {
      this.intersectionObserver.observe(this.messageTargets[0])
    }
    
  }

  disconnect() {
    if (!!this.subscription) {
      this.application.consumer.subscriptions.remove(this.subscription)
    }
    if (this.hasMessageTarget) {
      this.intersectionObserver.unobserve(this.messageTargets[0])
    }
  }

  _connected() {
  }

  _disconnected() {
  }


  _received(data) {
    console.log('message received', data)
    if (data.cableReady) {
      CableReady.perform(data.operations)
      this.messageContainerTarget.scrollTop = this.messageContainerTarget.scrollHeight;

      this.stimulate('MessagesReflex#touch_last_read')
    }
  }

  // This is the keydown event handler for the input field.
  handleNewMessageEnter(event) {
    if ((event.key === 'Enter' || event.keyCode === 13) && !event.shiftKey) {
      event.preventDefault();
      this.stimulate('MessagesReflex#create', this.newMessageFormTarget).then(() => {
        event.target.value = "";
        event.target.style.height = 'auto'
        event.target.style.height = (event.target.scrollHeight) + 'px';
      })
      // this.callStimulate(event.target);
    }
  }

  processIntersectionEntries(messageContainer) {
    messageContainer.forEach(message => {
      if (message.isIntersecting) {
        let currentMessage = this.messageTargets[0]
        this.intersectionObserver.unobserve(currentMessage)
        this.stimulate('MessagesReflex#fetch_more', currentMessage).then(() => {
          currentMessage.scrollIntoView()
          if (currentMessage !== this.messageTargets[0]) {
            this.intersectionObserver.observe(this.messageTargets[0])
          }
        })
      }
    })
  }
}

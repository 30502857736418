import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["locationStaff", "locationManager", "accountManager", "accountAdmin", "roleType", "locationError", "roleIds", "submitButton", "groups", 'groupLabel']
  connect() {
    this.updatePermissions()

  }

  updatePermissions() {
    let selectedValue = this.roleTypeTarget.value;
    console.log("Selected Value: ", selectedValue)
    //document.querySelector("input[name$='[role_ids][]']:checked").value

    if (selectedValue === 'Location Manager') {
      // document.getElementById('user_role_ids_1').checked = true
      if (this.locationManagerTargets.length === 1) { this.locationManagerTarget.checked = true }
      this.locationStaffTargets.forEach(target => {
          target.checked = false;
          target.parentElement.classList.add('d-none')
      })
      this.locationManagerTargets.forEach(target => {
        target.parentElement.classList.remove('d-none')
      })
      if (this.hasAccountAdminTarget) { this.accountAdminTarget.checked = false }
      if (this.hasAccountManagerTarget) { this.accountManagerTarget.checked = false }
    } else if (selectedValue === 'Location Staff') {
      if (this.locationStaffTargets.length === 1) { this.locationStaffTarget.checked = true }
      this.locationManagerTargets.forEach(target => {
        target.checked = false
        target.parentElement.classList.add('d-none')
      })
      this.locationStaffTargets.forEach(target => {
        target.parentElement.classList.remove('d-none')
      })
      if (this.hasAccountAdminTarget) { this.accountAdminTarget.checked = false }
      if (this.hasAccountManagerTarget) { this.accountManagerTarget.checked = false }
    } else if (selectedValue === 'Account Admin') {
      this.locationStaffTargets.forEach(target => {
        target.checked = false;
        target.parentElement.classList.add('d-none')
      })
      this.locationManagerTargets.forEach(target => {
        target.checked = false
        target.parentElement.classList.add('d-none')
      })
      if (this.hasAccountManagerTarget) { this.accountManagerTarget.checked = false }
      if (this.hasAccountAdminTarget) { this.accountAdminTarget.checked = true }
    } else if (selectedValue === 'Account Manager') {
      this.locationStaffTargets.forEach(target => {
        target.checked = false;
        target.parentElement.classList.add('d-none')
      })
      this.locationManagerTargets.forEach(target => {
        target.checked = false
        target.parentElement.classList.add('d-none')
      })
      if (this.hasAccountAdminTarget) { this.accountAdminTarget.checked = false }
      if (this.hasAccountManagerTarget) { this.accountManagerTarget.checked = true }
    }
    this.initializeGroups()
    this.checkError()
  }

  checkError() {
    // Clear role errors if present.
    let selectedCheckboxes = document.querySelectorAll("input[name$='[role_ids][]']:checked")
    if (selectedCheckboxes.length > 0) {
      this.locationErrorTarget.classList.add('d-none');
      this.roleTypeTarget.classList.remove("is-invalid");
      this.submitButtonTarget.disabled = false;
    } else {
      // disable the submit button.
      this.submitButtonTarget.disabled = true
    }
  }
  
  initializeGroups() {
    let selectedValue = this.roleTypeTarget.value;
    if (selectedValue === 'Location Manager') {
      this.locationManagerTargets.forEach(target => this.updateGroupDisplay(target));
    } else if (selectedValue === 'Location Staff') {
      this.locationStaffTargets.forEach(target => this.updateGroupDisplay(target));
    } else {
      this.groupsTargets.forEach(group => {
        group.classList.remove('d-none')
      })
    }
  }

  handleRoleCheck() {
    this.checkError()
    this.updateGroupDisplay(event.target);
  }

  // Iterate through membership group checkboxes to show/hide
  // If a location is de-selected, we want to hide and un-check the corresponding groups
  // If a location is selected, we want to show the corresponding groups for that location.
  updateGroupDisplay(element) {
    this.groupsTargets.forEach(group => {
      if (group.dataset.parent === `${element.dataset['roleType']}_${element.dataset['parentId']}`) {
        if (element.checked) {
          group.classList.remove('d-none');
        } else {
          group.classList.add('d-none')
          let checkbox = group.querySelector('input[type="checkbox"]')
          checkbox.checked = false
        }
      }
    })
  }
}
import ApplicationController from './application_controller'
/* This is the custom StimulusReflex controller for the Example Reflex.
 * Learn more at: https://docs.stimulusreflex.com
 */
export default class extends ApplicationController {
  static targets = ["status"]
  connect () {
    super.connect()
    // add your code here, if applicable
  }

  submitUpdate(event) {
    this.statusTarget.classList.remove('d-none');
    this.stimulate('InventoryCheckItems#update_item').then(() => {
      this.statusTarget.classList.add('d-none');
    })
  }
}

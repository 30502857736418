import ApplicationController from './application_controller'

/* This is the custom StimulusReflex controller for the Example Reflex.
 * Learn more at: https://docs.stimulusreflex.com
 */
export default class extends ApplicationController {
  static targets = ['unreadMessageCount']
  connect () {
    super.connect()

    this.subscription = this.application.consumer.subscriptions.create({ channel: "UserNotificationsChannel" }, {
      connected: this._connected.bind(this),
      disconnected: this._disconnected.bind(this),
      received: this._received.bind(this)
    })
    console.log('User notifications stimulus controller connected')
  }

  disconnect() {
    console.log('stimulus disconnecting for user notifications')
    if (!!this.subscription) {
      this.application.consumer.subscriptions.remove(this.subscription)
    }
  }

  _connected() {
    console.log("User notifications action cable connected")
  }

  _disconnected() {
    console.log("User notifications action cable disconnected")
  }

  _received(data) {
    console.log('data received in user notifications', data)
    this.unreadMessageCountTarget.innerHTML = data['unreadMessages']
    if (data['unreadMessages'] === 0) {
      this.unreadMessageCountTarget.classList.add('invisible')
    } else {
      this.unreadMessageCountTarget.classList.remove('invisible')
    }
    
    // if (data.cableReady) {
    //   CableReady.perform(data.operations)
    //   this.messagesTarget.scrollTop = this.messagesTarget.scrollHeight;
    // }
  }
}

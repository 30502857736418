import ApplicationController from './application_controller'

/* This is the custom StimulusReflex controller for the Example Reflex.
 * Learn more at: https://docs.stimulusreflex.com
 */
export default class extends ApplicationController {
  connect () {
    super.connect()
    // add your code here, if applicable
  }
  // Reflex callback:after updating a checklist item
  finalizeUpdate(element) {
    if (element.dataset.checklistComplete === 'true') {
      $(`#card_body_checklist_${element.dataset.checklistId}`).collapse('hide')
    }
  }
}
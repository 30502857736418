import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [
                    'storageLocationCheckboxes', 
                    'storageLocationWrapper', 
                    'vendorCheckboxes', 
                    'vendorWrapper', 
                    'submitButton',
                    'storageLocationToggle',
                    'vendorToggle'
                  ]

  connect () {
  }

  toggleStorageLocations (event) {
    if (event.target.value === 'All Storage Locations') {
      this.storageLocationWrapperTarget.classList.add('d-none')
      this.storageLocationCheckboxesTargets.forEach(checkbox => {
        checkbox.checked = false;
      })
    } else {
      this.storageLocationWrapperTarget.classList.remove('d-none')
      this.storageLocationCheckboxesTargets.forEach(checkbox => {
        checkbox.checked = true;
      })
    }
    this.validate();
  }

  toggleVendors (event) {
    if (event.target.value === 'All Vendors') {
      this.vendorWrapperTarget.classList.add('d-none')
      this.vendorCheckboxesTargets.forEach(checkbox => {
        checkbox.checked = false;
      })
    } else {
      this.vendorWrapperTarget.classList.remove('d-none')
      this.vendorCheckboxesTargets.forEach(checkbox => {
        checkbox.checked = true;
      })
    }
    this.validate();
  }

  validate (event) {
    this.submitButtonTarget.disabled = !(this.validateVendors() && this.validateStorageLocations());
  }

  validateVendors () {
    if (this.vendorToggleTarget.value === 'All Vendors') {
      return true;
    } else {
      return this.validateCheckboxes(this.vendorCheckboxesTargets)
    }
  }

  validateStorageLocations () {
    if (this.storageLocationToggleTarget.value === 'All Storage Locations') {
      return true
    } else {
      return this.validateCheckboxes(this.storageLocationCheckboxesTargets)
    }
  }

  validateCheckboxes (collection) {
    let valid = false;
    collection.forEach(checkbox => {
      if (checkbox.checked === true) {
        valid = true;
      }
    })
    return valid;
  }
}

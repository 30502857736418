import ApplicationController from './application_controller'

/* This is the custom StimulusReflex controller for the Example Reflex.
 * Learn more at: https://docs.stimulusreflex.com
 */
export default class extends ApplicationController {
  static targets = [ 'locations' ]
  connect () {
    super.connect()
    // add your code here, if applicable
  }

  beforeToggleAccount(element) {
    this.locationsTarget.disabled = true
  }

}
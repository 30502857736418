import ApplicationController from './application_controller'


export default class extends ApplicationController {
  // The controller expects a reflexController value because this controller is shared by Recipe ingredients and ParSchedule::Items
  static values = {
    reflexController: String
  }

  static targets = [ 'itemSearch', 'itemHidden', 'submit', 'unitSearch', 'unitHidden', 'quantity', 'field', 'delete', 'itemResults' ]
  connect () {
    super.connect();
    document.addEventListener('autocomplete.change', this.autocomplete.bind(this))
    this.showSubmit = this.showSubmit.bind(this)
    this.fieldTargets.forEach(target => {
      target.addEventListener('focus', this.showSubmit)
    });

    this.addItemKeydown = this.addItemKeydown.bind(this)
    this.itemSearchTarget.addEventListener('keydown', this.addItemKeydown)
    
    this.initForm();
  }

  disconnect() {
    this.fieldTargets.forEach(target => {
      target.removeEventListener('focus', this.showSubmit)
    });
    this.itemSearchTarget.removeEventListener('keydown', this.addItemKeydown)
  }

  autocomplete(event) {
    // console.log('in ingredients autocomplete event handler')
    this.initForm();
    // See which autocomplete was selected, update the 'orig' data values, and remove invalid class.
    if (event.target.contains(this.itemSearchTarget)) {
      this.itemHiddenTarget.dataset['originalValue'] = this.itemHiddenTarget.value
      this.itemSearchTarget.dataset['originalValue'] = this.itemSearchTarget.value
      this.itemSearchTarget.classList.remove('is-invalid')
    }
  }

  // If the user modifies the text in the search input, we clear the
  // associated hidden field and disable the form. Selecting a new item
  // from the autocomplete select will re-enable the form and set the hidden input.
  itemSearchOnChange(event) {
    // console.log('item search  on change')
    // console.log('current value:', this.itemSearchTarget.value)
    // console.log('original vlaue:', this.itemSearchTarget.dataset['originalValue'])
    if (this.itemSearchTarget.value !== this.itemSearchTarget.dataset['originalValue']) {
      // The input has changed, so clear the hidden input
      this.itemHiddenTarget.value = null;
      // Form submit should be disabled
      this.initForm();
    }
  }

  afterCreateInventoryItem(event) {
    // console.log('in after create inventory item')
    this.itemSearchTarget.focus();
  }
  afterCreateUnit(event) {
    // console.log('in afterCreateUnit')
    this.unitSearchTarget.focus();
  }

  afterCreateItem(event) {
    // console.log('in afterCreateItem')
    this.itemSearchTarget.focus();
    this.itemSearchTarget.scrollIntoView({behavior: 'smooth', block: 'center'})
  }

  // When the user leaves focus of the search field, we need to make sure
  // that a value has been selected from the autocomplete, otherise trigger
  // an error. 
  // Future Enchancement: search for exact match via reflex and select it
  // if the hidden field is blank. I.e. if someone types "cups" and quickly tabs
  // to the next field without selcting from the autocomplete dropdown.
  itemSearchOnBlur(event) {
    // event.stopImmediatePropagation()
    // console.log('in itemsearchonblur')
    // If the hidden field is not set, we need to indicate the error
    if (!this.itemHiddenTarget.value && !!this.itemSearchTarget.value) {
      this.stimulate(`${this.reflexControllerValue}#find_exact_item`, this.itemSearchTarget, { resolveLate: true }).then(() => {
        this.validateItemField();
        this.itemResultsTarget.innerHTML = ''
      })
    } else {
      this.validateItemField();
    }
  }

  // Allows a user to hit esc to cancel their action on changing an ingredient.
  itemKeyDown(event) {
    // console.log('in ingredients itemkeydown')
    if (event.key === 'Escape' || event.keyCode === 27) {
      if (!!this.itemSearchTarget.dataset['originalValue'] && !!this.itemHiddenTarget.dataset['originalValue']) {
        this.itemSearchTarget.value = this.itemSearchTarget.dataset['originalValue']
        this.itemHiddenTarget.value = this.itemHiddenTarget.dataset['originalValue']
        event.target.blur();
      }
    }
  }

  // Selecting a new item from the autocomplete select will re-enable the form and set the hidden input.
  unitSearchOnChange(event) {
    if (this.unitSearchTarget.value !== this.unitSearchTarget.dataset['originalValue']) {
      // Even though this is duplicative of what happens on the unit-search controller
      // We need to set the hidden value to null here as well because of possible race
      // condition prior to the initForm(); call
      // If the hiddentarget isn't nulled before it's called the submit button may remain enabled.
      this.unitHiddenTarget.value = null;
      this.initForm();
    }
  }

  showSubmit(event) {
    this.submitTarget.classList.remove('d-none');
    if (this.hasDeleteTarget) {
      this.deleteTarget.classList.add('d-none');
    }
  }

  // Keyboard enter events on our custom autocomplete results list for 
  // adding items need special handling to capture and trigger the reflex
  // unlick with the click event that we can directly attached with data-reflex
  // We're borrowing a bit of logic from the autocomplete library, and its
  // a bit hacky. But effectively we bind the keydown event to the search input
  // and capture the enter event, then locate the selected item and trigger
  // a reflex if it's one that's meant to add an item.
  addItemKeydown(event) {
    // console.log('in ingredients addItemKeydown')
    if (event.key === 'Enter' || event.keyCode === 13) {
      
      const selected = this.itemResultsTarget.querySelector(
        '[aria-selected="true"]'
      )
      // If we found a selected item and it has the dataset element indicating that 
      // it should trigger an added item.
      if (!!selected && !!selected.dataset['itemName']) {
        // We have to pass the input as the source element for the reflex so params get passed, and then we send the item and preparation names as 
        // arguments to the reflex action. Once again a little hacky.
        this.stimulate(`${this.reflexControllerValue}#create_inventory_item`, event.target, selected.dataset['itemName'], selected.dataset['preparationName'])
      }
    }
  }

  initForm () {
    // console.log('in ingredients initform')
    this.submitTarget.disabled = (!this.itemHiddenTarget.value || (this.hasUnitHiddenTarget && !this.unitHiddenTarget.value) || (this.hasQuantityTarget && (!this.quantityTarget.value || this.quantityTarget.value == 0)))
    // if (!!this.itemHiddenTarget.value) {
    //   this.itemSearchTarget.classList.add('is-valid')
    // }
  }

  validateItemField() {
    if (!!this.itemHiddenTarget.value) {
      // this.itemSearchTarget.classList.add('is-valid')
      this.itemSearchTarget.classList.remove('is-invalid')
    } else {
      // this.itemSearchTarget.classList.remove('is-valid')
      this.itemSearchTarget.classList.add('is-invalid')
    }
  }
}

import { Controller } from "stimulus"

export default class extends Controller {
  // static targets = [ "source" ]
  static targets = ['stripePublishableKey']

  connect () {
  }

  createSession(event) {
    console.log(event.target.dataset.priceId)
    const publishableKey = this.stripePublishableKeyTarget.dataset.stripePublishableKey
    const stripe = Stripe(publishableKey)
  
    this.fetchStripeSession(event.target.dataset.priceId).then(function(data) {
      console.log('Session data:', data)
      stripe.redirectToCheckout({
        sessionId: data['session_id']
      });
    })
  }

  fetchStripeSession(priceId) {
    // https://bloggie.io/@kinopyo/sending-non-get-requests-with-fetch-javascript-api-in-rails
    const csrfToken = document.querySelector("[name='csrf-token']").content
    return fetch("/stripe/checkouts", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        'X-CSRF-Token': csrfToken
      },
      body: JSON.stringify({
        priceId: priceId
      })
    }).then(function(result) {
      return result.json();
    });
  }
}
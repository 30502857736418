import ApplicationController from './application_controller'


export default class extends ApplicationController {
  static targets = [ 'unitSearch', 'unitResults', 'unitHidden' ]
  connect () {
    super.connect();
    document.addEventListener('autocomplete.change', this.autocomplete.bind(this))
    
    // this.addUnitKeydown = this.addUnitKeydown.bind(this)
    this.unitSearchOnChange = this.unitSearchOnChange.bind(this)
    this.unitSearchOnBlur = this.unitSearchOnBlur.bind(this)
    this.unitKeyDown = this.unitKeyDown.bind(this)
    // this.unitSearchTarget.addEventListener('keydown', this.addUnitKeydown)
    this.unitSearchTarget.addEventListener('debounced:input', this.unitSearchOnChange)
    this.unitSearchTarget.addEventListener('blur', this.unitSearchOnBlur)
    this.unitSearchTarget.addEventListener('keydown', this.unitKeyDown)
  }

  disconnect() {
    // this.unitSearchTarget.removeEventListener('keydown', this.addUnitKeydown)
    this.unitSearchTarget.removeEventListener('debounced:input', this.unitSearchOnChange)
    this.unitSearchTarget.removeEventListener('blur', this.unitSearchOnBlur)
    this.unitSearchTarget.addEventListener('keydown', this.unitKeyDown)
  }

  autocomplete(event) {
    // this.initForm();
    // See which autocomplete was selected, update the 'orig' data values, and remove invalid class.
    if (event.target.contains(this.unitSearchTarget)) {
      this.unitHiddenTarget.dataset['originalValue'] = this.unitHiddenTarget.value
      this.unitSearchTarget.dataset['originalValue'] = this.unitSearchTarget.value
      this.unitSearchTarget.classList.remove('is-invalid')
      this.initSearchField();
    }
    
  }

  // If the user modifies the text in the search input, we clear the
  // associated hidden field and disable the form. Selecting a new item
  // from the autocomplete select will re-enable the form and set the hidden input.
  unitSearchOnChange(event) {
    if (this.unitSearchTarget.value !== this.unitSearchTarget.dataset['originalValue']) {
      // The input has changed, so clear the hidden input
      this.unitHiddenTarget.value = null;
      this.unitSearchTarget.classList.remove('is-valid');
    } else {
      this.initSearchField();
    }
  }

  initSearchField() {
    if (!!this.unitHiddenTarget.value) {
      this.unitSearchTarget.classList.add('is-valid');
    }
  }

  // When the user leaves focus of the search field, we need to make sure
  // that a value has been selected from the autocomplete, otherise trigger
  // an error. 
  // Future Enchancement: search for exact match via reflex and select it
  // if the hidden field is blank. I.e. if someone types "cups" and quickly tabs
  // to the next field without selcting from the autocomplete dropdown.
  unitSearchOnBlur(event) {
    // If the hidden field is not set, we need to indicate the error
    if (!this.unitHiddenTarget.value && !!this.unitSearchTarget.value) {
      this.stimulate('Units#find_exact_unit', this.unitSearchTarget, { resolveLate: true }, event.target.dataset['target']).then(() => {
        if (!this.unitHiddenTarget.value) {
          this.unitSearchTarget.classList.add('is-invalid')
        } else { this.initSearchField(); }
      })
    } else {
      this.unitSearchTarget.classList.remove('is-invalid')
      this.initSearchField();
    }
  }

  // Allows a user to hit esc to cancel their action on changing an ingredient.
  // Or allows a user to hit enter to create a new item
  // IMPORTANT: this controller must be attached higher in the dom than the autocomplete controller for
  // the ENTER event to work as intended, otherwise it will not capture the selected value.
  unitKeyDown(event) {
    if (event.key === 'Escape' || event.keyCode === 27) {
      if (!!this.unitSearchTarget.dataset['originalValue'] && !!this.unitHiddenTarget.dataset['originalValue']) {
        this.unitSearchTarget.value = this.unitSearchTarget.dataset['originalValue']
        this.unitHiddenTarget.value = this.unitHiddenTarget.dataset['originalValue']
        event.target.blur();
      }
    } else if (event.key === 'Enter' || event.keyCode === 13) {
      const selected = this.unitResultsTarget.querySelector(
        '[aria-selected="true"]'
      )
      // If we found a selected item and it has the dataset element indicating that 
      // it should trigger an added item.
      if (!!selected && !!selected.dataset['unitName']) {
        
        // We have to pass the input as the source element for the reflex so params get passed, and then we send the item and preparation names as 
        // arguments to the reflex action. Once again a little hacky.
        this.stimulate('Units#create_unit', event.target, selected.dataset['unitName'], selected.dataset['target'])
      }
    }
  }
}

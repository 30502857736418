import { Controller } from "stimulus"

export default class extends Controller {
  static targets = []

  connect() {
  }

  disableWith() {
    event.preventDefault();
    event.target.disabled = true;
    event.target.value = event.target.dataset.disableWith;
    event.target.form.submit();
  }
}
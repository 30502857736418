import ApplicationController from './application_controller'

/* This is the custom StimulusReflex controller for the Example Reflex.
 * Learn more at: https://docs.stimulusreflex.com
 */
export default class extends ApplicationController {
  connect () {
    super.connect()
    // add your code here, if applicable
  }
  
  preventSubmit(event) {
    event.preventDefault()
    return false;
  }

  beforeReflex(element) {
    document.getElementById('item-results').classList.add('d-none');
    document.getElementById('item-count').classList.add('d-none');
    let loadingIndicator = document.getElementById('item-count-loading')
    loadingIndicator.classList.add('d-flex');
    loadingIndicator.classList.remove('d-none');
    document.getElementById('items-loading-table').classList.remove('d-none');
  }

  beforeClear(anchorElement) {
    document.querySelectorAll(".clear-button-text").forEach(e => {e.classList.toggle('d-none')});
    console.log("Clearing filters...")
  }

  createItem(event) {
    event.preventDefault();
    this.stimulate('Tabular#create_item', event.target).then(() => {
      let nameInput = document.getElementById('inventory_item_name')
      nameInput.value = '';
      nameInput.focus();
    });
    return false;
  } 
  // This seems to somehow cause a duplication of the reflex call. Bug?
  // beforeCreateItem(event) {
  //   console.log("creating new item...");
  //   document.getElementById('new_item_submit').innerHTML ='<span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span><span>Adding...</span>';
  // }

}
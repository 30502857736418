import ApplicationController from './application_controller'
import consumer from "channels/consumer"

/* This is the custom StimulusReflex controller for the Example Reflex.
 * Learn more at: https://docs.stimulusreflex.com
 */
export default class extends ApplicationController {
  static targets = ['count']
  connect () {
    super.connect()
      
    this.subscription = this.application.consumer.subscriptions.create({ channel: "UnreadsChannel", id: this.data.get("id") }, {
      connected: this._connected.bind(this),
      disconnected: this._disconnected.bind(this),
      received: this._received.bind(this)
    })
    console.log('unreads stimulus connected for', this.data.get("id"))
  }

  disconnect() {
    console.log('stimulus disconnecting for unreads', this.data.get("id"))
    if (!!this.subscription) {
      this.application.consumer.subscriptions.remove(this.subscription)
    }
  }

  _connected() {
    console.log("Unreads action cable connected for ", this.data.get("id"))
  }

  _disconnected() {
    console.log("disconnected action cable for ", this.data.get("id"))
  }

  _received(data) {
    console.log('data received in unreads', data)
    this.countTarget.innerHTML = data['count']
    if (data['count'] === 0) {
      this.countTarget.classList.add('invisible')
    } else {
      this.countTarget.classList.remove('invisible')
    }
    
    // if (data.cableReady) {
    //   CableReady.perform(data.operations)
    //   this.messagesTarget.scrollTop = this.messagesTarget.scrollHeight;
    // }
  }
}

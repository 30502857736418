import ApplicationController from './application_controller'

/* This is the custom StimulusReflex controller for the Example Reflex.
 * Learn more at: https://docs.stimulusreflex.com
 */
export default class extends ApplicationController {
  static targets = [ "oneTimeSubmit" ]
  connect () {
    super.connect()
    // add your code here, if applicable
  }

  beforeAddSchedule(element) {
    let submitButton = $("input[type=submit]",element);
    // let submitButton = document.getElementById(`submit_button_location_routine_schedule`)
    submitButton.prop('disabled', true);
  }

  finalizeAddSchedule(element) {
    // We connect to the recurrence controller and call its resetForm method
    // See: https://leastbad.com/stimulus-power-move
    $(element).parents('.modal').modal('hide')
    if (element.recurrence) {
      element.recurrence.resetForm();
    }
    let submitButton = $("input[type=submit]",element);
    // let submitButton = document.getElementById(`submit_button_location_routine_schedule`)
    submitButton.prop('disabled', false);
  }

  validateOneTime(event) {
    if (!!event.target.value) {
      this.oneTimeSubmitTarget.removeAttribute('disabled')
    }
  }
}

import ApplicationController from './application_controller'

/* This is the custom StimulusReflex controller for the Example Reflex.
 * Learn more at: https://docs.stimulusreflex.com
 */
export default class extends ApplicationController {
  static targets = ["hide", "show", "submit"]
  connect () {
    super.connect()
    // add your code here, if applicable
  }

  // When the text area is focused, we select all text and toggle the action buttons
  itemEditFocus(event) {
    event.target.select();
    this.activate()
  }

  // This is the keydown event handler for the input field.
  handleItemEditEnter(event) {
    if ((event.key === 'Enter' || event.keyCode === 13) && !event.shiftKey) {
      event.preventDefault();
      event.target.blur(); // This currently will trigger the stimulate method to be called twice because we hook on both events.
      // this.callStimulate(event.target);
    } else if (event.key === 'Escape' || event.keyCode === 27) {
      event.target.value = event.target.dataset['origValue']
      event.target.blur();
    }
  }
  // Event handler for when the text area field for an existing item is blurred.
  // The purpose of this is to handle unsaved edits and/or restore if no chagnes are made.
  handleItemBlur(event) {
    // If the item is unchanged, we just restore the form.
    if (event.target.value === event.target.dataset['origValue']) {
      this.deactivate();
    } else {
      this.callStimulate(event.target);
    }
  }

  callStimulate(element) {
    this.setElementLoading(this.submitTarget);
    this.stimulate(element.dataset['stimulateReflex'], element, { resolveLate: true }).then(() => {
      this.deactivate();
      this.setElementReady(this.submitTarget)
      element.setAttribute("data-orig-value", element.value);
    })
  }
  // Show or hide the corresponding targets when the form is activated by a click or other action.
  activate () {
    this.hideTargets.forEach(target => {
      target.classList.add('d-none')
      if (target.classList.contains('d-flex')) {
        target.setAttribute("data-display-class", 'd-flex');
        target.classList.remove('d-flex')
      }
    })

    this.showTargets.forEach(target => {
      target.classList.remove('d-none')
    })
  }

  // Restore the form to its original visual state to indicate that it's not being currently edited.
  deactivate() {
    this.hideTargets.forEach(target => {
      // $(target).hide()
      target.classList.remove('d-none')
      if (!!target.dataset['displayClass']) {
        target.classList.add(target.dataset['displayClass'])
      } else {
        target.classList.add('d-flex')
      }
    })

    this.showTargets.forEach(target => {
      target.classList.add('d-none')
    })

  }
}

// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

// if you're using webpacker for stylesheets, uncomment
// import 'stylesheets/application.scss'
import '../marketing/css/marketing.scss'
require("typeface-quando")
import Rails from '@rails/ujs'
import "@hotwired/turbo-rails"
import * as ActiveStorage from '@rails/activestorage'

// if you're using ActionText, uncomment
// import 'trix'
// import '@rails/actiontext'

// if you're using ActionCable channel classes, uncomment
// import 'channels'

// if you want to use webpacker for images, uncomment
// const images = require.context('../images', true)
// const imagePath = name => images(name, true)

// require("@fortawesome/fontawesome-free")
// require("typeface-quando")

// Added JQuery and Popper for Bootstrap
// require('jquery')
// require('popper.js')
// require("bootstrap")
// window.jQuery = $;
// window.$ = $;

import "controllers"
Rails.start()
ActiveStorage.start()
import ApplicationController from './application_controller'

/* This is the custom StimulusReflex controller for the Example Reflex.
 * Learn more at: https://docs.stimulusreflex.com
 */
export default class extends ApplicationController {
  static targets = [ "vendorSku" ]
  connect () {
    super.connect()
    // Consider eliminating this line. Right now it's not necessary, 
    // but hypothetically there could be a sitaution with a nil vendor item 
    // where the select has a selected item and we still want to initialize.
    this.vendorSkuTargets.forEach(target => this.updateSkuPrice(target))
  }

  showPackDetails() {
    this.updateSkuPrice(event.target);
  }

  updateSkuPrice(element) {
    let itemTarget = element.dataset.lineId
    document.getElementById('vendor_pack_size_' + itemTarget).innerHTML = element.options[element.selectedIndex].dataset.packSize || '--'
    document.getElementById('vendor_pack_price_' + itemTarget).innerHTML = element.options[element.selectedIndex].dataset.price || '--'
  
  }

  toggleSku(event) {
    this.updateSkuPrice(event.target)
  }
  beforeUpdateOrderItem(element) {
    //this.updateSkuPrice(element);
    console.log("in the relfex callback")
    console.log("Element datatset", element.dataset.lineId)
    document.getElementById(`total_size_value_${element.dataset.lineId}`).innerHTML = "<span class='spinner-border spinner-border-sm text-muted' role='status' />"
    document.getElementById(`total_price_${element.dataset.lineId}`).innerHTML = "<span class='spinner-border spinner-border-sm text-muted' role='status' />"
  }

  updateQuantity(event) {
    if (!!event.target.value) {
      this.stimulate('OrdersReflex#update_order_item', event.target)
    }
  }
}

import ApplicationController from './application_controller'
export default class extends ApplicationController {
  static targets = [ 'spinner' ]
  connect () {
    super.connect()
  }

  beforeUpdate(element, reflex, noop, reflexId) {
    this.spinnerTarget.classList.remove('invisible')
  }

  afterUpdate(element, reflex, noop, reflexId) {
    this.spinnerTarget.classList.add('invisible')
  }
}

// ./controllers/flatpickr_controller.js
// import stimulus-flatpickr wrapper controller to extend it
import Flatpickr from 'stimulus-flatpickr'
import confirmDatePlugin from 'flatpickr/dist/plugins/confirmDate/confirmDate.js'



// you can also import a translation file
// import { French } from 'flatpickr/dist/l10n/fr.js'

// import a theme (could be in your main CSS entry too...)
// import 'flatpickr/dist/flatpickr.css'
// import 'flatpickr/dist/plugins/confirmDate/confirmDate.css'


// create a new Stimulus controller by extending stimulus-flatpickr wrapper controller
export default class extends Flatpickr {
  connect() {
    // sets your language (you can also set some global setting for all time pickers)
    this.config = {
      ...this.config,
      dateFormat: "Y-m-d h:i K",
      plugins: [new confirmDatePlugin({
        showAlways: false,
        confirmIcon: "<i class='fa fa-check ml-2'></i>",
      })]
    }
    super.connect()
  }

  // all flatpickr hooks are available as callbacks in your Stimulus controller
  // change(selectedDates, dateStr, instance) {
  //   console.log('the callback returns the selected dates', selectedDates)
  //   console.log('but returns it also as a string', dateStr)
  //   console.log('and the flatpickr instance', instance)
  // }

  // This is done because of this: https://github.com/flatpickr/flatpickr/issues/1730
  open(selectedDates, dateStr, instance) {
    if (!!instance.element.dataset['modalParent']) {
      let modal = document.getElementById(instance.element.dataset['modalParent'])
      modal.removeAttribute('tabindex');
    }
  }

  close(selectedDates, dateStr, instance) {
    if (!!instance.element.dataset['modalParent']) {
      let modal = document.getElementById(instance.element.dataset['modalParent'])
      modal.setAttribute('tabindex', -1);
    }
  }
}
import { Controller } from "stimulus"
import ApplicationController from './application_controller'
export default class extends ApplicationController {
  static targets = ['ingredientHeadingForm', 'ingredientNotesForm', 'ingredientHeadingBtn', 'ingredientNotesBtn']
  
  connect() {
    super.connect()
  }
  restoreIngredientActions(event) {
    event.preventDefault();
    this.resetForms();
  }

  resetForms() {
    this.ingredientHeadingFormTarget.reset();
    this.ingredientHeadingFormTarget.querySelector("input[type='submit']").disabled = false;
    this.ingredientHeadingFormTarget.classList.add('d-none');
    this.ingredientNotesFormTarget.reset();
    this.ingredientNotesFormTarget.querySelector("input[type='submit']").disabled = false;
    this.ingredientNotesFormTarget.classList.add('d-none');
    this.ingredientHeadingBtnTarget.classList.remove('d-none');
    this.ingredientNotesBtnTarget.classList.remove('d-none');
  }

  toggleIngredientHeading(event) {
    event.preventDefault();
    this.ingredientHeadingFormTarget.classList.remove('d-none');
    this.ingredientHeadingBtnTarget.classList.add('d-none');
    this.ingredientNotesBtnTarget.classList.add('d-none');
    this.ingredientHeadingFormTarget.getElementsByClassName('form-control')[0].focus();
  }

  toggleIngredientNotes(event) {
    event.preventDefault();
    this.ingredientNotesFormTarget.classList.remove('d-none');
    this.ingredientHeadingBtnTarget.classList.add('d-none');
    this.ingredientNotesBtnTarget.classList.add('d-none');
    this.ingredientNotesFormTarget.getElementsByTagName('trix-editor')[0].focus();
  }

  createHeading(event) {
    event.preventDefault();
    this.stimulate('RecipeEdit#create_heading', event.target).then(() => {
      this.resetForms();
    });
  }

  createNote(event) {
    event.preventDefault();
    this.stimulate('RecipeEdit#create_note', event.target).then(() => {
      this.resetForms();
    });
  }
}
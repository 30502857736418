import { Controller } from "stimulus"
import Sortable from "sortablejs"
import Rails from '@rails/ujs';

export default class extends Controller {

  connect() {
    // this.element is the element that the controller is attached to.
    // By initializing sortable on it, all children of the element will be sortable.
    let options = {
      // onStart:
      animation: 150,
      // ghostClass: 'sortable-ghost', // this is the default
      handle: ".grip",
      onEnd: this.end.bind(this)
    }
    if (!!this.element.dataset.group) {
      options.group = this.element.dataset.group
    }
    this.sortable = Sortable.create(this.element, options)
  }

  end(event) {
    let id = event.item.dataset.id
    let data = new FormData();
    console.log(event)
    data.append("position", event.newIndex + 1);
    if (!!event.to.dataset.parentId) {
      data.append("parent_id", event.to.dataset.parentId)
    }
    Rails.ajax({
      url: this.data.get("url").replace(":id", id),
      type: 'PATCH',
      data: data,
    })
  }
}

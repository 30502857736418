import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [ "source" ]

  connect () {
  }

  copy(event) {
    this.sourceTarget.select()
    document.execCommand("copy")
    event.target.innerHTML = 'Copied!'
  }
}
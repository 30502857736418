import { Controller } from 'stimulus'
import StimulusReflex from 'stimulus_reflex'
import debounced from 'debounced'

/* This is your ApplicationController.
 * All StimulusReflex controllers should inherit from this class.
 *
 * Example:
 *
 *   import ApplicationController from './application_controller'
 *
 *   export default class extends ApplicationController { ... }
 *
 * Learn more at: https://docs.stimulusreflex.com
 */
export default class extends Controller {
  connect () {
    StimulusReflex.register(this)
    debounced.initialize()
  }

  isPreview() {
    return document.documentElement.hasAttribute('data-turbo-preview')
  }

  selectText(event) {
    event.target.select();
  }

  /* Application-wide lifecycle methods
   *
   * Use these methods to handle lifecycle concerns for the entire application.
   * Using the lifecycle is optional, so feel free to delete these stubs if you don't need them.
   *
   * Arguments:
   *
   *   element - the element that triggered the reflex
   *             may be different than the Stimulus controller's this.element
   *
   *   reflex - the name of the reflex e.g. "Example#demo"
   *
   *   error/noop - the error message (for reflexError), otherwise null
   *
   *   reflexId - a UUID4 or developer-provided unique identifier for each Reflex
   */

  beforeReflex (element, reflex, noop, reflexId) {
    // document.body.classList.add('wait')
    // console.log(element)
    switch(element.tagName) {
      
      case 'FORM':
        let submitButton = element.querySelector("input[type='submit']")
        if (!!submitButton) {
          this.setElementLoading(submitButton)
        }
        break;
      // case 'INPUT':
      //   // code block
      //   break;
      case 'BUTTON':  
        if (!!element.dataset['disableWith']) {
            element.disabled = true;
            element.innerHTML = `<span class='spinner-border spinner-border-sm' role='status'></span> <span>${element.dataset['disableWith']}</span>`
          }
      case 'A':
        if (!!element.dataset['disableWith']) {
          element.disabled = true;
          element.innerHTML = `<span class='spinner-border spinner-border-sm' role='status'></span> <span>${element.dataset['disableWith']}</span>`
        } 
    }

    let saveStatus = document.getElementById('save-status');
    if (!!saveStatus) {
      saveStatus.innerHTML = "<span class='spinner-border spinner-border-sm mr-1' role='status' aria-hidden='true'></span>Saving..."
    }
  }

  reflexSuccess (element, reflex, noop, reflexId) {
    let saveStatus = document.getElementById('save-status');
    if (!!saveStatus) {
      saveStatus.innerHTML = "<i class='fas fa-cloud' role='status' aria-hidden='true'></i> Saved"
    }
  }

  reflexError (element, reflex, error, reflexId) {
    // show error message etc...
  }

  afterReflex (element, reflex, noop, reflexId) {
    // document.body.classList.remove('wait')
    switch(element?.tagName) {
      case 'FORM':
        let submitButton = element.querySelector("input[type='submit']")
        if (!!submitButton) {
          this.setElementReady(submitButton)
        }
        break;
    //   case 'INPUT':
    //     // code block
    //     break;
    //   case 'BUTTON':
    //     this.enableElement(element)
    }
  }

  setElementLoading(element) {
    let disableWith = element.dataset['disableWith']
    
    if (!!disableWith) {
      element.disabled = true;
      element.setAttribute("data-disable-with", element.value);
      element.value = disableWith
    } 
  }

  setElementReady(element) {
    let disableWith = element.dataset['disableWith']
    
    if (!!disableWith) {
      element.disabled = false;
      element.setAttribute("data-disable-with", element.value);
      element.value = disableWith
    } 
  }
}

import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [ "interval", "startTime", "daysCheckboxes", "monthsCheckboxes", "daysOfMonth", "daysOfWeekForMonth", "frequency", "form", 'summary', 'monthTypeDays', 'monthTypeWeek', 'monthScheduleOptions' ]
  connect() {
    // https://leastbad.com/stimulus-power-move
    this.element[this.identifier] = this
    this.init()
  }

  init() {
    this.toggleDisplay();
    this.toggleMonthChooser();
  }

  changeFrequency() {
    this.toggleDisplay();
  }

  resetForm() {
    this.frequencyTarget.value = 'Daily'
    this.intervalTarget.value = 1
    this.startTimeTarget.value = "09:00"
    this.toggleDisplay();
    this.toggleMonthChooser();
  }

  showEdit() {
    this.init()
    this.formTarget.classList.remove("d-none")
    if (this.hasSummaryTarget) {
      this.summaryTarget.classList.add("d-none")
      this.summaryTarget.classList.remove("d-inline-block")
      
    }
  }

  hideForm() {
    event.preventDefault();
    this.formTarget.classList.add("d-none")
    if (this.hasSummaryTarget) {
      this.summaryTarget.classList.remove("d-none")
      this.summaryTarget.classList.add("d-inline-block")
    }
  }

  cancelEdit() {
    this.frequencyTarget.value = this.frequencyTarget.dataset.original;
    this.intervalTarget.value = this.intervalTarget.dataset.original;
    this.startTimeTarget.value = this.startTimeTarget.dataset.original;
    this.hideForm();
    this.toggleDisplay();
  }

  toggleMonthChooser() {
    //"daysOfMonth", "daysOfWeekForMonth"
    if (this.monthTypeDaysTarget.checked) {
      this.daysOfMonthTarget.classList.remove('d-none');
      this.daysOfWeekForMonthTarget.classList.add('d-none');
    }
    if (this.monthTypeWeekTarget.checked) {
      this.daysOfMonthTarget.classList.add('d-none');
      this.daysOfWeekForMonthTarget.classList.remove('d-none');
    }
  }

  toggleDisplay() {
    switch (this.frequencyTarget.value) {
      case 'Weekly':
        this.daysCheckboxesTarget.classList.remove("d-none");
        this.monthScheduleOptionsTarget.classList.add("d-none");
        this.monthsCheckboxesTarget.classList.add("d-none");
        break;
      case 'Daily': 
        this.daysCheckboxesTarget.classList.add("d-none");
        this.monthScheduleOptionsTarget.classList.add("d-none");
        this.monthsCheckboxesTarget.classList.add("d-none");
        break;
      case 'Monthly':
        this.monthScheduleOptionsTarget.classList.remove("d-none");
        this.daysCheckboxesTarget.classList.add("d-none");
        this.monthsCheckboxesTarget.classList.add("d-none");
        break;
      case 'Yearly':
        this.daysCheckboxesTarget.classList.add("d-none");
        this.monthScheduleOptionsTarget.classList.remove("d-none");
        this.monthsCheckboxesTarget.classList.remove("d-none");
        break;
    }
    let newValue = '';
    for (let option of this.frequencyTarget.children) {
      newValue = option.innerHTML.replace(/s/g, "")
      if (this.intervalTarget.value > 1) {
        newValue += 's';
      }
      option.innerHTML = newValue;
    }
  }
}
import ApplicationController from './application_controller'

/* This is the custom StimulusReflex controller for the Example Reflex.
 * Learn more at: https://docs.stimulusreflex.com
 */
export default class extends ApplicationController {
  connect () {
    super.connect()
    // add your code here, if applicable
  }

  finalizeUpdateSchedule(element) {
    let el = document.getElementById(`location_routine_schedule_${element.dataset.id}`)
    el.recurrence.hideForm();
  }

  afterDeleteSchedule(element) {
    // TODO: replace this with non jquery if we remove jquery.
    $(`#location_routine_schedule_${element.dataset.id}`).fadeOut(300, function(){
      $(this).remove();
    });

  }
}

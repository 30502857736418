import ApplicationController from './application_controller'

export default class extends ApplicationController {
  static targets = [ "onHandValue", "needValue", "editOnHandValue", "editNeedValue" ]
  connect () {
    super.connect()
    // add your code here, if applicable
  }

  toggleNeedValue(event) {
    event.preventDefault();
    this.editNeedValueTarget.classList.add('invisible')
    this.needValueTarget.disabled = false;
    this.needValueTarget.focus();
    this.needValueTarget.select();
  }

  toggleOnHandValue(event) {
    event.preventDefault();
    this.editOnHandValueTarget.classList.add('invisible')
    this.onHandValueTarget.disabled = false;
    this.onHandValueTarget.focus();
    this.onHandValueTarget.select();
  }
}

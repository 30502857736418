import ApplicationController from './application_controller'

/* This is the custom StimulusReflex controller for the Example Reflex.
 * Learn more at: https://docs.stimulusreflex.com
 */
export default class extends ApplicationController {
  connect () {
    super.connect()
    // add your code here, if applicable
  }
  
  // Reflex callback: Before creating an item, we need to disable the submit button
  beforeCreateItem(element) {
    let submitButton = document.getElementById(`new_item_submit_checklist_definition_${element.dataset.checklistDefinitionId}`)
    submitButton.disabled = true
  }

  // Reflex callback: When creating an item, we need to:
  // Reset the form, focuse hte pinput, re-enable the submit button, and reset the text area height
  afterCreateItem(element) {
    element.reset()
    let nameInput = document.getElementById(`new_name_input_checklist_definition_${element.dataset.checklistDefinitionId}`)
    let submitButton = document.getElementById(`new_item_submit_checklist_definition_${element.dataset.checklistDefinitionId}`)
    nameInput.focus();
    nameInput.style.height = 'auto';
    nameInput.style.height = (this.scrollHeight) + 'px';
    submitButton.disabled = false
  }

  createItemSubmit(event) {
    event.preventDefault();
    this.stimulate('RoutineDefinitionsEdit#create_item', event.target)
  }

  // Reflex callback after an item is updated
  // Toggle the form button and action buttons back to default
  // Update the "original" value for purposes of checking for unsaved changes.
  updateDescription(event) {
    event.preventDefault()
    this.setElementLoading(event.target)
    this.stimulate('RoutineDefinitionsEditReflex#update_checklist_item_definition', event.target, { resolveLate: true }).then(() => {
      $(`#description_modal_checklist_item_definition_${event.target.dataset.id}`).modal('hide')
      this.setElementReady(event.target)
    })
  }


  finalizeAddChecklist(element) {
    let el = document.getElementById(`components_routine_definition_${element.dataset.id}`).lastElementChild.querySelector('textarea')
    $([document.documentElement, document.body]).animate({
      scrollTop: $(el).offset().top
    }, 400);
    console.log(el)
    el.focus();
  }

  finalizeAddCommentBox(element) {
    let el = document.getElementById(`components_routine_definition_${element.dataset.id}`).lastElementChild.querySelector('.text-area-plain')
    $([document.documentElement, document.body]).animate({
      scrollTop: $(el).offset().top
    }, 400);
    el.focus();
  }

  finalizeAddInventoryCheck(element) {
    let el = document.getElementById(`components_routine_definition_${element.dataset.id}`).lastElementChild.querySelector('.card')
    $([document.documentElement, document.body]).animate({
      scrollTop: $(el).offset().top
    }, 400);
  }

  handleEnterAllowDefault(event) {
    if ((event.key === 'Enter' || event.keyCode === 13) && !event.shiftKey) {
      // event.target.blur();
      event.preventDefault();
      // event.target.form.submit();
      this.stimulate('RoutineDefinitionsEdit#create_item', event.target.form)
    }
  }
}
